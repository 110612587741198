<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <div class="tab_content">
                <p><span>修改维修资金代缴信息</span> <span>您所在的当前位置：首页 >> 修改维修资金代缴信息</span></p>
            </div>

            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="">
                        <el-input v-model="form.name" placeholder="请输入搜索内容" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="search">搜索</button>
                    <button @click.prevent="add">新建</button>
                </el-form>
            </div>
            <!--表格-->
            <template>
                <el-table
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        stripe
                        @selection-change="handleSelectionChange">
                    <el-table-column
                            type="selection">
                    </el-table-column>
                    <el-table-column
                            property="villageName"
                            label="小区名称"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="province"
                            label="所属省"

                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="city"
                            label="所属市"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="county"
                            label="所属区"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="coveredarea"
                            label="建筑面积"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="coveredarea"
                            label="占地面积"
                            show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                            property="settingTime"
                            label="建立时间"
                            show-overflow-tooltip>
                    </el-table-column>

                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
            </template>
            <!--分页-->
        </div>
    </div>
</template>

<script>
    import qs from "qs"

    export default {
        name: "first_table",
        data() {
            return {
                form: {
                    name: "",
                    region: '',
                },
                tableData: [],
                multipleSelection: [],
                totalNum: 15
            }
        },
        mounted() {
            this.common()
        },
        methods: {
            common(currentPage = 0) {
                const that = this;
                let developersId = JSON.parse(localStorage.getItem("user")).id;
                const data = {
                    access_token: localStorage.getItem("token"),
                    page: currentPage,
                    limit: 15,
                    developersId
                }
                $.ajax({
                    url: "/api/api-public/opevillage/list",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        console.log(res);
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    }
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleCurrentChange(val) {//当前页数改变
                this.common(val)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            deleteTable(id) {
                const that = this;
                console.log(id)
                const data = {
                    access_token: localStorage.getItem("token")
                }
                $.ajax({
                    url: "/api/api-public/opevillage/delete/" + id,
                    data: data,
                    type: "delete",
                    success: function (res) {
                        console.log(res);
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    }
                })
            }, add() {
                //新增按钮
                this.$router.push({path: "/addVillage"});
            },  search(currentPage = 0) {
                //搜索按钮
                const that = this;
                let developersId = JSON.parse(localStorage.getItem("user")).id;
                const data = {
                    access_token: localStorage.getItem("token"),
                    page: currentPage,
                    limit: 15,
                    villageName: this.form.name,//搜索框的值
                    developersId
                }
                $.ajax({
                    url: "/api/api-public/opevillage/list",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        console.log(res);
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../assets/css/table/first_table.css";
</style>